
import { computed, defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { VueEditor } from "vue3-editor";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { AxiosRequestConfig } from "axios";
import { useI18n } from "vue-i18n";
import { event } from "vue-gtag";

interface Research {
  subject: string;
  title: string;
  module: string;
  desc: string;
  deadline: string;
  resources: File[];
  trimester: string;
  teachers: string[];
  levels: string[];
}

interface File {
  name: string;
  url: string;
  storedName: string;
}

interface Subject {
  _id: string;
  name: string;
}

interface Teacher {
  _id: string;
  firstName: string;
  lastName: string;
  employee: {
    firstName: string;
    lastName: string;
  };
  levels: string[];
  subjects: string[];
}

export default defineComponent({
  name: "edit-research",
  components: {
    ErrorMessage,
    Field,
    Form,
    VueEditor,
  },
  props: {
    id: String,
  },
  setup() {
    const { t } = useI18n();
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    // eslint-disable-next-line
    const upload = ref<any>(null);

    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const subjects = computed(() =>
      store.getters.currentUser.subjects.filter((s) => s.status !== "inactive")
    );
    const levels = computed(() => store.getters.currentUser.levels);
    const teacherList = ref<Teacher[]>([]);

    const researchID = route.params.id;

    const spec = ref<string>("0");

    const jwtTrimester = JwtService.getTrimester();
    let trimester = "1";
    if (jwtTrimester && jwtTrimester != "0") trimester = jwtTrimester;

    const research = ref<Research>({
      subject: "",
      title: "",
      module: "",
      desc: "",
      deadline: "",
      resources: [],
      trimester,
      teachers: [],
      levels: [],
    });

    const validationSchema = Yup.object().shape({
      title: Yup.string().required(t("course.requiredTitle")),
      subject: Yup.string().required(t("course.requiredSubject")),
      teachers: Yup.array().test(
        "required-teachers",
        t("course.requiredTeachers"),
        (value) => {
          if (spec.value == "0") return true;
          if (spec.value == "1" && (value?.length == 0 || value == undefined))
            return false;
          else return true;
        }
      ),
      levels: Yup.array()
        .min(1, t("course.requiredLevels"))
        .required(t("course.requiredLevels")),
    });

    onMounted(async () => {
      await ApiService.get(`/lms/advisor/research/${researchID}`)
        .then(({ data }) => {
          research.value = {
            subject: data.subject._id,
            title: data.title,
            module: data.module,
            desc: data.desc,
            deadline: data.deadline,
            resources: [],
            trimester: data.trimester ? data.trimester : "0",
            levels: data.levels.map((l) => String(l)),
            teachers: data.teachers,
          };

          if (research.value.teachers.length > 0) spec.value = "1";

          data.resources.forEach((file) => {
            research.value.resources.push({
              storedName: file,
              name: getFileName(file),
              url: getFileUrl(file),
            });
          });
        })
        .catch((e) => {
          console.log(e);
        });

      ApiService.post("/schedules/filter", {
        query: {
          status: { $ne: "inactive" },
          hidden: { $ne: true },
        },
        aggregation: [
          {
            $lookup: {
              from: "teachers",
              localField: "teacher",
              foreignField: "_id",
              as: "teacher",
            },
          },
          {
            $lookup: {
              from: "teachers",
              localField: "teacher.employee",
              foreignField: "_id",
              as: "teacherName",
            },
          },
          {
            $lookup: {
              from: "modules",
              localField: "module",
              foreignField: "_id",
              as: "module",
            },
          },
          {
            $lookup: {
              from: "modulespecifics",
              localField: "moduleSpecific",
              foreignField: "_id",
              as: "moduleSpecific",
            },
          },
          {
            $lookup: {
              from: "classrooms",
              localField: "classeRoom",
              foreignField: "_id",
              as: "classeRoom",
            },
          },
          {
            $project: {
              _id: 1,
              teacherName: {
                firstName: 1,
                lastName: 1,
              },
              teacher: {
                $let: {
                  vars: {
                    teacherVar: {
                      $arrayElemAt: ["$teacher", 0],
                    },
                  },
                  in: {
                    _id: "$$teacherVar._id",
                    employee: "$$teacherVar.employee",
                  },
                },
              },
              classeRoom: {
                $let: {
                  vars: {
                    classVar: {
                      $arrayElemAt: ["$classeRoom", 0],
                    },
                  },
                  in: {
                    name: "$$classVar.name",
                    _id: "$$classVar._id",
                    level: "$$classVar.level",
                  },
                },
              },
              module: {
                $let: {
                  vars: {
                    moduleVar: {
                      $arrayElemAt: ["$module", 0],
                    },
                  },
                  in: {
                    name: "$$moduleVar.name",
                    _id: "$$moduleVar._id",
                    status: "$$moduleVar.status",
                    subjects: "$$moduleVar.subjects",
                  },
                },
              },
              moduleSpecific: {
                $let: {
                  vars: {
                    moduleSpecificVar: {
                      $arrayElemAt: ["$moduleSpecific", 0],
                    },
                  },
                  in: {
                    name: "$$moduleSpecificVar.name",
                    _id: "$$moduleSpecificVar._id",
                    status: "$$moduleSpecificVar.status",
                    subjects: "$$moduleSpecificVar.subjects",
                  },
                },
              },
              subject: 1,
              type: 1,
            },
          },
        ],
      })
        .then(({ data }) => {
          data = data
            .map((d) => {
              if (!d.type) d.type = "S";
              return d;
            })
            .filter(
              (d) =>
                (!d.subject || d.subject.status !== "inactive") &&
                (!d.module || d.module.status !== "inactive") &&
                (!d.moduleSpecific || d.moduleSpecific.status !== "inactive")
            );
          data.forEach((data) => {
            const teacherSubjects =
              data.type == "S"
                ? [data.subject]
                : data.type == "MP"
                ? data.module.subjects
                : data.moduleSpecific.subjects;
            data.classeRoom.level = String(data.classeRoom.level);
            const t = teacherList.value.find(
              (teacher) => teacher._id == data.teacher._id
            );
            if (
              levels.value.includes(data.classeRoom.level) &&
              subjects.value.find((subject: Subject) =>
                teacherSubjects.includes(subject._id)
              )
            ) {
              if (!t) {
                data.teacher.levels = [data.classeRoom.level];
                data.teacher.subjects = teacherSubjects;
                teacherList.value.push(data.teacher);
              } else {
                if (!t.levels.includes(data.classeRoom.level))
                  t.levels.push(data.classeRoom.level);
                t.subjects.push(...teacherSubjects);
              }
            }
          });
          teacherList.value.map((t) => {
            t.subjects = t.subjects.filter(
              (s, index) => t.subjects.indexOf(s) === index
            );
          });
        })
        .catch((e) => console.log(e));
    });

    const getFileName = (file: string): string => {
      const t = file.indexOf("--");
      const fileName = file.substring(t == -1 ? 0 : t + 2);
      return fileName;
    };

    const getFileUrl = (file: string): string => {
      return (
        store.getters.serverConfigUrl.base_url + "/" + file.replace(/\\/g, "//")
      );
    };

    // eslint-disable-next-line
    const uploadFiles = ref<any>([]);

    const updateUploadFiles = (file, files) => {
      uploadFiles.value = files;
    };

    const submit = () => {
      const data = new FormData();

      if (!research.value.deadline) research.value.deadline = "";

      if (research.value.teachers && research.value.teachers?.length != 0) {
        research.value.teachers?.forEach((st: string) => {
          data.append("teachers", st);
        });
      }

      for (const name in research.value)
        if (!["teachers", "levels", "resources"].includes(name))
          data.append(name, research.value[name]);

      if (research.value.levels.length != 0) {
        research.value.levels.forEach((level: string) => {
          data.append("levels", level);
        });
      }

      if (uploadFiles.value.length > 0) {
        uploadFiles.value.forEach((file) => {
          if (file.raw) data.append("resources", file.raw, file.raw.name);
          else data.append("resources[]", file.storedName);
        });
      } else {
        research.value.resources.map((file) => {
          data.append("resources[]", file.storedName);
        });
      }

      if (!submitButtonRef.value) {
        return;
      }

      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

      ApiService.patch(
        `/lms/advisor/research/${researchID}`,
        data as AxiosRequestConfig
      )
        .then(() => {
          event("Edit research", {
            event_category: "Research",
            event_label: "Research section",
            value: 1,
          });

          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;

            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }

          Swal.fire({
            text: t("course.editedResearchInfo"),
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: t("course.okay"),
            customClass: {
              confirmButton: "btn btn-primary",
            },
          }).then(() => {
            router.push(`/research/${researchID}`);
          });
        })
        .catch((err) => {
          if (err.response.status == 413)
            Swal.fire({
              text: t("course.filesTooLarge"),
              icon: "warning",
              buttonsStyling: false,
              confirmButtonText: t("course.okay"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          else
            Swal.fire({
              text: t("course.errorText"),
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: t("course.tryAgain"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;

            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }
        });
    };

    const clearTeachers = () => {
      research.value.teachers = [];
    };

    const avaliableTeachers = computed(() =>
      teacherList.value.filter((t: Teacher) =>
        t.levels.find((l) => research.value.levels.includes(l))
      )
    );

    const handleExceed = () => {
      Swal.fire({
        text: t("course.maxFilesUploadExceed"),
        icon: "info",
        buttonsStyling: false,
        confirmButtonText: t("course.tryAgain"),
        customClass: {
          confirmButton: "btn fw-bold btn-light-info",
        },
      });
    };

    return {
      t,
      researchID,
      subjects,
      spec,
      clearTeachers,
      avaliableTeachers,
      upload,
      research,
      validationSchema,
      submit,
      submitButtonRef,
      teacherList,
      levels,
      updateUploadFiles,
      handleExceed,
    };
  },
});
